@font-face {
  font-family: 'PressStart';
  src: url('../public/fonts/PressStart2P-Regular.ttf') format("truetype");
  font-weight: normal;
  font-style:normal;
}

@font-face {
  font-family: 'SpaceMono';
  src: url('../public/fonts/SpaceMono-Regular.ttf') format("truetype");
  font-weight:normal;
  font-style:normal;
}

@font-face {
  font-family: 'SpaceMono-Bold';
  src: url('../public/fonts/SpaceMono-Bold.ttf') format("truetype");
  font-weight: bold;
  font-style:normal;
}

@font-face {
  font-family: 'SpaceMono-Italic';
  src: url('../public/fonts/SpaceMono-Italic.ttf') format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'SpaceMono-BoldItalic';
  src: url('../public/fonts/SpaceMono-BoldItalic.ttf') format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'VT323-Regular';
  src: url('../public/fonts/VT323-Regular.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}

@keyframes glitch {
  30% {
    text-shadow: 4px -4px 0px rgba(60, 85, 255, 1);
  }
  32% {
    text-shadow: -4px 4px 0px rgba(255,85,0,1);
  }
  0%, 28%, 34% {
    text-shadow: none;
  }
  31%, 32% {
    transform: translate(-7px,7px);
  }
  32%, 33% {
    transform: translate(7px,-7px);
  }
  31%, 33% {
    transform: translatex(0);
}
  50% {
    text-shadow: 4px 4px 0px rgba(60, 85, 255, 1);
  }
  52% {
    text-shadow: -4px -4px 0px rgba(255,85,0,1);
  }
  48%, 54% {
    text-shadow: none;
  }
  49%, 52% {
    transform: translatex(0);
  }
  50% {
    transform: translate(7px,7px);
  }
  51% {
    transform: translate(-7px,-7px);
  }
}

body {
  margin: 0;
  padding:0;
  font-family: 'SpaceMono';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  color:white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#bg-video {
  object-fit: cover;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: -1000;
  min-height: 100%;
  min-width: 100%;
  transform: translateX(-50%) translateY(-50%);

}

.not-visible {
  visibility: hidden;
}
.colorized {
  font-weight: bold;
  background: linear-gradient(-45deg, #74e8fc, #7d26f5, #f09af8, #e936f7);
  background-size:400% 400%;
  background-clip: text;
  color:transparent;
  animation: color-change 5s ease infinite;
}

#mintable-logo2 {
  background: #e73c7e;
  height:30px;
}

@media (max-width:767px) {
  .container {
    display:block;
    width:80%;
    min-width: 250px;
    height:auto;
    overflow: hidden;
  }

  #menu-top {
    display: none;
  }

  #mobile-menu {
    position:fixed;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
    width:200px;
    right:20px;
    top:20px;
    height:100px;
    z-index:1000;
  }

  #mobile-menu-list {
    box-sizing: border-box;
    padding:80px 10px 5px 10px;
    position:fixed;
    right:0px;
    top:0px;
    display: flex;
    height:100%;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
    background: rgba(0, 0, 0);
    z-index: 500;
    transition: right 1s;
  }

  #mobile-menu-list.hidden {
    right:-220px;
    transition:right 1s;
  }

  #mobile-menu-list a {
    margin-top:10px;
    margin-right:10px;
    font-family: 'PressStart';
    color:white;
    line-height: 1.2;
    font-size: 80%;
    text-decoration: none;
    background: black;
    padding:2px;
  }
  #mobile-menu-list a:hover {
    color:yellow;
  }

  .mobile-menu-item {
    height:30px;
  }

  #menu-icon {
    position: relative;
    width:50px;
    z-index:1000;
  }

  #content {
    position:relative;
    top:0px;
    min-width: 100%;
  }

  #treasures-moonerals, #treasures-moonpods, #treasures-moonerals-txt, #treasures-moonpods-txt {
    display:block!important;;
    margin:15px auto !important;
  }

}

@media (min-width: 768px) {
  .container {
    display:block;
    width:60%;
    min-width: 250px;
    height:auto;
    overflow: hidden;
  }

  #menu-top {
    box-sizing: border-box;
    position:fixed;
    top:0%;
    left:0%;
    min-width: 100%;
    height:50px;
    background: rgba(2, 2, 2);
    padding:8px;
    z-index:1000;
  }
  #menu-logo {
    float:left;
    margin: 0 0 0 20px;
  }

  #menu-logo2 {
    font-family: 'PressStart';
    font-size:80%;
    float:left;
    margin: 10px 0 0 120px;
  }
  #menu-logo3 {
    font-family: 'PressStart';
    font-size:80%;
    float:left;
    margin: 10px 0 0 40px;
  }
  #menu-logo4 {
    font-family: 'PressStart';
    font-size:80%;
    float:left;
    margin: 10px 0 0 40px;
  }

  #menu-mint {
    margin:0 30px;
  }

  #menu-mint:hover {
    cursor:pointer;
  }
  .menu-item {
    height:30px;
    margin: 0 5px;
    float:right;
  }

  #mobile-menu {
    display: none;
  }

  #content {
    position:relative;
    top:40px;
    min-width: 100%;
    height:100%;
  }

  #treasures-moonerals, #treasures-moonpods-txt {
    float:right;
  }

  #treasures-moonpods,#treasures-moonerals-txt  {
    float:left;
  }

}

#content {
  padding:0;
  margin:0;
  left:0;
  transition:left 1s;
}

.hidden {
  display:none;
}

.gradient-up {
  display: block;
  position: absolute;
  bottom:0;
  left:0;
  width:100%;
  height:20%;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
}

.gradient-down-narrow {
  display: block;
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:5%;
  background-image: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,1));
}

.gradient-down {
  display: block;
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:20%;
  background-image: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,1));
}

.full-container {
  display:block;
  width:95%;
  min-width: 250px;
  height:auto;
  overflow: hidden;
}

.center-txt-content {
  margin:0 auto;
  font-size:150%;
}

.center-content {
  margin: 35px auto;
}

.center-content-item {
  display:block;
  margin: 35px auto;
}


.left-content-item {
  display:block;
  margin: 35px;
  margin-bottom:10px;
  box-sizing: border-box;
}

.shift-left {
  position:relative;
  left:100px;
}

.content-txt {
  text-align:center;
  font-size:80%;
}

.content-title {
  font-family: 'VT323-Regular';
  font-size:200%;
  background: linear-gradient(-45deg, #74e8fc, #7d26f5, #f09af8, #e936f7);
  background-size:400% 400%;
  background-clip: text;
  color:transparent;
  animation: color-change 5s ease infinite;
  text-align:center;
}

@media (max-width:767px) {
  .content-txt-left {
    text-align: center;
    margin: 20px 0;
  }
}
@media (min-width: 768px) {
  .content-txt-left {
    text-align: left;
    margin: 20px 0;
  }
}


.mint-button {
  width:150px;
  height:30px;
  text-align:center;
  line-height: 30px;
  background: linear-gradient(-45deg, #74e8fc, #7d26f5, #f09af8, #e936f7);
  background-size:400% 400%;
  color:white;
  animation: color-change 5s ease infinite;
  font-size: 100%;
}


#hero-banner > .container > a:link {
  text-decoration: none;
}

.mint-button-top {
  width:150px;
  height:30px;
  text-align:center;
  line-height: 30px;
  background: linear-gradient(-45deg, #74e8fc, #7d26f5, #f09af8, #e936f7);
  background-size:400% 400%;
  color:white;
  animation: color-change 5s ease infinite;
  font-family: 'VT323-Regular';
  font-size: 160%;
  margin-right:10px;
}

#menu-top>  a:link {
  text-decoration: none;
}

@keyframes color-change {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.mint-button:hover {
  cursor: pointer;
}

.inactive-mint {
  color: gray !important;
  vertical-align: middle;
}

.inactive-mint:hover {
  cursor: not-allowed;
  color: gray !important;
}

.headertxt {
  font-family:'VT323-Regular';
  font-size: 180%;
  line-height: 1.7;
}

.headertxt-larger {
  font-family:'VT323-Regular';
  font-size: 300%;
  line-height: 1.7;
}

.headertxt-small {
  font-family: 'VT323-Regular';
  font-size:160%;
  line-height: 1.7;
}

.roadmap-header {
  font-size:180%;
}
.roadmap-text {
  font-size:120%;
}

#hero-banner {
  position:relative;
  width:100%;
  height:auto;
  min-height: 10px;
  overflow: hidden;
  font-family:'VT323-Regular';
  font-size: 160%;
  line-height: 1.5em;
  padding:0;
}

#hero-banner video[poster] {
  width: 100%;
}

#hero-video {
  width:100%;
  height:100%;
}


#text-one {
  font-family:'SpaceMono';
  position:relative;
  width:100%;
  min-height:10px;
  overflow: hidden;
  background: rgb(2, 2, 2, 0.7);
}

#claim-section {
   position:relative;
   box-sizing:border-box;
   width:100%;
   overflow:hidden;
 }
@media (max-width:767px) {
  #claim-section {
    min-height:500px;
    max-height:600px;
  }

  #claim-section-photo {
    position:absolute;
    height:100%;
    z-index:-10;
  }
}
@media (min-width:767px) {
  #claim-section {
    min-height:500px;
    max-height:800px;
  }
  #claim-section-photo {
    position:absolute;
    width:100%;
    z-index:-10;
  }
}
#claim-button-group {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buy-moonz-button {
  width:200px;
  height:50px;
  text-align:center;
  line-height: 50px;
  border-radius:5px;
  background: linear-gradient(-45deg, #74e8fc, #7d26f5, #f09af8, #e936f7);
  background-size:400% 400%;
  color:white;
  animation: color-change 5s ease infinite;
  font-family: 'VT323-Regular';
  font-size: 30px;
}


a:link {
  text-decoration: none;
  color:inherit;
}


#text-two {
  position:relative;
  width:100%;
  min-height:150px;
  overflow: hidden;
  background: rgb(2, 2, 2, 0.7);
}

#crew-graphic {
  position:relative;
  top:0;
  object-fit: cover;
  width:100%;
  height:auto;
  min-height: 150px;
  overflow: hidden;
  background: black;
}

#space-station {
  position:relative;
  display: block;
  width:100%;
  max-height:800px;
  min-height:300px;
  background: black;
}

#spinning-station {
  display: block;
  position: relative;
  width:70%;
  max-width:500px;
  top:50%;
  left:50%;
  transform: translate(-50%);
}

#crew-graphic-photo {
  width:100%;
  border:0;
}


#roadmap {
  position: relative;
  width:100%;
  height:auto;
  color:#2edabe;
  font-family: 'VT323-Regular';
}
.roadmap-header, .roadmap-text {
  animation: glitch 7.0s linear infinite;
}

#roadmap-background {
  object-fit: cover;
  width:100%;
  height:100%;
  position:absolute;
  top:0;
  left:0;
  z-index: -500;
}

#roadmap-line-1 {
  position: relative;
  padding-left:0;
  height:auto;
}

#phase-one-toggle {
  display: block;
  box-sizing: border-box;
  margin-left:50px;
  padding:10px;
  border: 3px #2edabe solid;
  font-family: 'VT323-Regular';
  font-size:160%;
  border-radius:5px;
}


@media (max-width: 767px) {
  #roadmap > .container {
    padding-left: 0 ;
    margin-right:0;
    width:90%;
  }

  #roadmap7{
    width:60%;
    min-width:150px;
  }

  #roadmap7.roadmap-desc {
    width:60%;
    min-width:150px;
  }

  #roadmap-line {
    position: relative;
    padding-left:0;
    height:auto;
    margin-top:-50px;
  }
  #roadmap-content {
    position: relative;
    width:100%;
    display: flex;
    flex-direction: column;
    font-size: 90%;
  }
  #roadmap-content-phase-1 {
    position: relative;
    width:100%;
    display: flex;
    flex-direction: column;
    font-size: 90%;
  }
}

@media (min-width: 768px) {
  #roadmap > .container {
    padding-left: 10% ;
    width:90%;
  }

  #roadmap7.roadmap-desc {
    min-width: 250px;
  }

  #roadmap-line {
    position: relative;
    padding-left:10%;
    height:auto;
    margin-top:-50px;
  }
  #roadmap-content {
    position: relative;
    width:100%;
    display: flex;
    flex-direction: column;
  }
  #roadmap-content-phase-1 {
    position: relative;
    width:100%;
    display: flex;
    flex-direction: column;
  }

}

#roadmap-content-path {
  position:absolute;
  left:45px;
  width:10px;
  border-left: #2edabe solid 3px;
  z-index: -3;
  display: block;
}

#pointer-handler {
  position:sticky;
  position:-webkit-sticky;
  top:50%;
  bottom:50px;
  left:20px;
  width:30%;
  height:30px;
  z-index: 20;
}

@media (max-width: 767px) {
  #pointer {
    position:absolute;
    top:60px;
    left:10px;
    width:90px;
  }
}

@media (min-width: 768px) {
  #pointer {
    position:absolute;
    top:60px;
    left:30px;
    width:90px;
  }
}

#highlight {
  display:block;
  position: absolute;
  top:45px;
  left:80px;
  width:100%;
  height:90px;
  background: none;

}

#pointer-graphic {
  width:35px;
}

.roadmap-item {
  display:flex;
  align-items: center;
  margin:20px 10px;
  width:90%;
  min-width:250px;
  position:relative;
  z-index: 0;
}

.roadmap-planet {
  position:relative;
  z-index:-50;
  display:inline-block;
  width:0px;
  margin:auto 0;
  margin-right:70px;
}

@media (min-width: 768px) {
  .roadmap-desc {
    display:inline-block;
    width:80%;
    min-width: 200px;
    padding-left: 25px;
    margin-left:25px;
    position: relative;
    z-index:0;
  }
}

@media (min-width:500px) and (max-width: 767px) {
  .roadmap-desc {
    display:inline-block;
    width:90%;
    min-width: 200px;
    padding-left: 25px;
    margin-left:25px;
    position: relative;
    z-index:0;
  }

}

@media (max-width: 499px) {
  .roadmap-desc {
    display:inline-block;
    width:100%;
    min-width: 200px;
    padding-left: 15px;
    margin-left:15px;
    position: relative;
    z-index:0;
  }
}

#text-three {
  position:relative;
  width:100%;
  min-height:150px;
  overflow: hidden;
  background: black;
}

#tos-crew {
  position:relative;
  width:100%;
  min-height:150px;
  overflow: hidden;
  background: rgb(2, 2, 2);
}



@media (min-width: 768px) {
  .flex-container{
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: center;
    width:90%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .crew {
    display:flex;
    flex-direction: row;
    height:350px;
    text-align:center;
    font-family: 'VT323-Regular';
    font-size:120%;
    line-height:1;
  }

  .crew img {
    width:90%;
    max-width: 250px;
    min-width:150px
  }
}

@media (max-width:767px ) and (min-width: 450px) {
  .flex-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .crew {
    display:flex;
    flex-direction: row;
    justify-content: center;
    width:100%;
    height:350px;
    text-align:center;
    font-family: 'VT323-Regular';
    font-size:120%;
    line-height:1;
    margin:0 auto;
  }

  .crew img {
    width:90%;
    max-width: 250px;
    min-width: 150px;
  }
}

@media (max-width: 449px) {
  .flex-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .crew {
    display:flex;
    flex-direction: row;
    width:100%;
    height:300px;
    text-align:center;
    font-family: 'VT323-Regular';
    font-size:160%;
    line-height:1;
    margin:0 auto;
  }

  .crew img {
    width:90%;
    max-width: 250px;
    min-width: 150px;
  }
}


#footer-first, #footer-third {
  display:block;
  height:60px;
}

#footer-second {
  display:block;
  width:150px;

}

#footer-first-header {
  font-family:'VT323-Regular';
  line-height: 2;
  font-size:150%;
}

.footer-item {
   height:30px;
   margin: 0 5px;
 }

@media (max-width: 767px) {
  #footer {
    height:auto;
    min-height:100px;
    overflow: hidden;
    padding:0 0 0 30px;
    background: rgb(2, 2, 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
  }
  #footer-container {
    width:100%;
    position:relative;
    display: block;
    border-top:white solid thin;
    margin-bottom:0px;
    height:0px;
  }
  #footer-first {
    position:relative;
    font-size: 80%;
    display: flex;
    flex-direction:column;
    align-items: center;
    height:auto;
  }
  #footer-second {
    position:relative;
    width:150px;
  }
  #footer-third {
    position:relative;
    font-size:30%;
    line-height: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 768px) {
  #footer {
    height:auto;
    min-height:100px;
    overflow: hidden;
    padding:0 0 0 30px;
    background: rgb(2, 2, 2);
  }

  #footer-container {
    width:96%;
    position:absolute;
    left:50%;
    transform:translateX(-50%);
    display: block;
    border-top:white solid thin;
    margin-bottom:1px;
    height:1px;
  }
  #footer-first {
    position: absolute;
    left:2%;
    bottom:30px;
    font-size: 80%;
    display: flex;
    flex-direction:column;
    align-content: baseline;
  }
  #footer-second {
    position:absolute;
    left:50%;
    transform: translateX(-50%);
    bottom:22px;
  }
  #footer-third {
    position:absolute;
    right:2%;
    bottom:20px;
    font-size:30%;
    line-height: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}